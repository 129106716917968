<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-card-title primary-title />
          <v-card-text>
            <v-row class="mt-12">
              <v-col
                v-for="(value, index) in fields"
                :key="index"
                class="align"
                cols="6"
              >
                <v-row>
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0 mb-9"
                    cols="4"
                  >
                    <div>
                      <span
                        class="font-weight-bold"
                        :class="value.class"
                      >
                        {{
                          $t('messages.' + value.text)
                        }}
                      </span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes('required')
                              ? '*'
                              : ''
                            : ''
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    v-else
                    cols="4"
                  />
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-textarea
                      v-if="value.type === 'textarea'"
                      v-model="value.value"
                      rows="1"
                      row-height="15"
                      :placeholder="$t(value.placeholder)"
                      clearable
                      outlined
                    />
                    <validation-provider
                      v-else-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              clearable
                              outlined
                              autocomplete="off"
                              :error-messages="errors[0]"
                              :placeholder="$t(value.placeholder)"
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                locale="ja-jn"
                                no-title
                                scrollable
                                :max="value.max_date ? value.max_date : ''"
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  {{ $t('messages.cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs['menu' + value.key][0].save(
                                      value.value
                                    )
                                  "
                                >
                                  {{ $t('messages.ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          clearable
                          outlined
                          :error-messages="errors[0]"
                          :placeholder="$t(value.placeholder)"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-autocomplete
                        v-if="value.key === 'inputCheckEmployee'"
                        v-model="value.value"
                        :name="value.key"
                        :items="employeeList"
                        :error-messages="errors[0]"
                        :item-text="item => item.agencyId + '_' + item.name"
                        :placeholder="$t(value.placeholder)"
                        item-value="value"
                        clearable
                        outlined
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        clearable
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'checkbox'"
                      v-slot="{ errors }"
                      :name="value.key"
                    >
                      <v-checkbox
                        v-model="value.value"
                        :name="value.key"
                        :error-messages="errors[0]"
                        color="#0F56B3"
                        style="margin-top: -23px; width: 5%"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'upload'"
                      v-slot="{ errors }"
                      :name="value.key"
                    >
                      <v-file-input
                        show-size
                        prepend-icon="mdi-camera"
                        :name="value.key"
                        accept="image/*"
                        outlined
                        :error-messages="errors[0]"
                        clearable
                        @change="selectImage"
                      />
                      <v-btn
                        color="primary"
                        :disabled="disabledPreview"
                        @click="previewDialog = true"
                      >
                        {{ $t('button.preview') }}
                      </v-btn>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--others-->

            <!--button-->
            <v-row align="center" />
          </v-card-text>
          <v-card-actions />
        </v-card>
        <v-row class="d-flex justify-end mt-3">
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              large
              block
              color="info"
              type="submit"
            >
              <span class="text-h5 pr-3 pl-3">{{ $t('messages.signup') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              large
              block
              color="info"
            >
              <span
                class="text-h5 pr-3 pl-3"
                @click="$router.push('/customer-list/')"
              >{{ $t('messages.close') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-dialog
      v-model="previewDialog"
      max-width="500px"
      max-height="500px"
    >
      <v-img
        max-height="500"
        max-width="500"
        contain
        class="grey darken-4"
        :src="preview"
      />
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import _ from 'lodash'
  import FormData from 'form-data'

  export default {
    name: 'CustomerAdd',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        rePasswordCheck: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        fields: [
          {
            text: 'factoryName',
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'contactPhone',
            key: 'contactPhone',
            value: '',
            type: 'text',
            validate: 'required|phone',
          },
          {
            text: 'email',
            key: 'email',
            value: '',
            type: 'text',
            validate: 'required|email',
            placeholder: 'placeholder.email',
          },
          {
            text: 'deadlineAfterTransferMail',
            key: 'invoiceSendDeadline',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'responsiblePerson',
            key: 'inChargedEmployee',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'sendAddress',
            key: 'sendAddress',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'personInput',
            key: 'inputEmployee',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'contactAddress',
            key: 'contactAddress',
            value: '',
            type: 'text',
          },
          {
            text: 'inputCheckEmployee',
            key: 'inputCheckEmployee',
            value: '',
            type: 'select',
            validate: 'required',
          },
          {
            text: 'payday',
            key: 'payday',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'personTransfer',
            key: 'paymentCheckEmployee',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'holidays',
            key: 'weekendHoliday',
            value: '',
            type: 'text',
          },
          {
            key: 'note',
            text: 'note',
            value: '',
            type: 'textarea',
            class: 'red--text',
          },
          {
            key: '',
            text: '',
            value: '',
            type: 'spacer',
            class: '',
          },
          {
            key: 'isFixed',
            text: 'fixed',
            value: false,
            type: 'checkbox',
          },
          {
            key: 'isOtNightShift',
            text: 'isOtNightShift',
            value: false,
            type: 'checkbox',
          },
          {
            key: 'upload',
            text: 'uploadImage',
            value: [],
            type: 'upload',
            validate: 'required',
          },
        ],
        avatar: null,
        previewDialog: false,
        preview: null,
        disabledPreview: false,
      }
    },
    computed: {
      ...get('customer', ['message', 'status', 'error', 'newCustomer']),
      employeeList: get('employee@list.data.employees'),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      message (value) {
        if (value === 'success') {
        // this.$router.push({ path: '/customer-list', query: { success: 'true' } })
        }
      },
      colleges (value) {
        const field = this.fields.find(o => o.key === 'collegeId')
        value.forEach(item => {
          field.items.push({
            value: item.id,
            text: item.name,
          })
        })
      },
      newCustomer (value) {
        this.$store.dispatch(
          'customer/getNewPass',
          value.data.result.user.password,
        )
        this.$router.push({ path: '/customer-detail/' + value.data.result.id })
      },
      avatar: {
        handler (value) {
          if (value === null) {
            this.disabledPreview = true
          } else {
            this.disabledPreview = false
          }
        },
        deep: true,
        immediate: true,
      },
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'))
      const data = JSON.parse(_.get(user, 'data', {}))
      const type = _.get(data, 'type', 0)

      if (type !== 1 && type !== 2) {
        this.$router.push('/')
      }
    },
    mounted () {
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
      extend('password', {
        params: ['target'],
        validate (value, target) {
          if (typeof target === 'object') return value === target[0]
          else if (typeof target === 'string' || typeof target === 'number') {
            return value === target
          }
        },
        message: this.$t('validator.confirm_password_invalid'),
      })
      extend('passwordRegex', {
        validate (value) {
          return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
            value,
          )
        },
        message:
          '英大文字・英小文字・数字を組み合わせて8文字以上で入力してください',
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(
            value,
          )
        },
        message: this.$t('数字のみ入力してください。'),
      })
      this.$store.dispatch('employee/getEmployees')
    },
    methods: {
      selectImage (value) {
        if (value !== undefined) {
          this.avatar = value
          this.preview = URL.createObjectURL(value)
          URL.revokeObjectURL(value)
        } else {
          this.preview = null
        }
      },
      onSubmit: _.debounce(
        function () {
          const payload = {
            name: this.fields.find(o => o.key === 'name').value,
            email: this.fields.find(o => o.key === 'email').value,
            contactPhone: this.fields.find(o => o.key === 'contactPhone').value,
            contactAddress: this.fields.find(o => o.key === 'contactAddress').value,
            payday: this.fields.find(o => o.key === 'payday').value,
            sendAddress: this.fields.find(o => o.key === 'sendAddress').value,
            invoiceSendDeadline: this.fields.find(
              o => o.key === 'invoiceSendDeadline',
            ).value,
            inputCheckEmployee: this.fields.find(
              o => o.key === 'inputCheckEmployee',
            ).value,
            inputEmployee: this.fields.find(o => o.key === 'inputEmployee').value,
            inChargedEmployee: this.fields.find(o => o.key === 'inChargedEmployee')
              .value,
            paymentCheckEmployee: this.fields.find(
              o => o.key === 'paymentCheckEmployee',
            ).value,
            note: this.fields.find(o => o.key === 'note').value,
            weekendHoliday: this.fields.find(o => o.key === 'weekendHoliday').value,
            isFixed: this.fields.find(o => o.key === 'isFixed').value,
            isOtNightShift: this.fields.find(o => o.key === 'isOtNightShift').value,
            avatar: this.avatar,
          }
          var formData = new FormData()
          Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value)
          })
          this.$store.dispatch('customer/create', formData)
        }, 500),
      hasErrors (errors) {
        return errors.length > 0
      },
    },
  }
</script>
